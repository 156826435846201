<template>
  <div class="flex self-center container mx-auto justify-center max-w-2xl">
    <LoadSpinner v-if="isLoading" />
    <template v-else-if="!isLoading || isClaimFetched">
      <FindYourStayCard v-if="!isClaimFetched" class="max-w-2xl" />
    </template>
  </div>
</template>

<script lang="ts" setup>
const { getReservation, setClaimError, claimByReferenceCode } = useWebsiteStore();
const { isClaimFetched, reservationStage, referenceCode } = storeToRefs(useWebsiteStore());

const isLoading = ref(true);
const route = useRoute()

if ('shackle_reference_code' in route.query) {
  const code = route.query.shackle_reference_code as string
  const lengthValid = code.length === 7
  const containsOnlyAlphaNumeric = /^[a-zA-Z0-9]+$/.test(code)

  referenceCode.value = ''

  if (lengthValid && containsOnlyAlphaNumeric) referenceCode.value = code
}

onMounted(async () => {
  const store = useWebsiteStore();
  
  // Ensure the store is hydrated from localStorage
  await store.$persist();
  
  if (!isClaimFetched.value && referenceCode.value) {
    try {
      await claimByReferenceCode(referenceCode.value);
      isLoading.value = false;
    } catch (error) {
      setClaimError('That code is invalid. Please double check it and try again.');
      isLoading.value = false;
    }
  } else if (isClaimFetched.value && localStorage.getItem("reservationName")) {
    try {
      await getReservation(String(localStorage.getItem("reservationName")));
      isLoading.value = false;

      // prevent first error if reservationStage is not set yet
      if (reservationStage.value === "STAGE_UNSPECIFIED") return;

      if (
        ["CANCELED", "PENDING_CHECK_OUT", "PENDING_CHECK_OUT_OTHER_WINDOWS_REQUIRE_CLOSURE", "CHECKED_OUT"].includes(
          reservationStage.value
        )
      ) {
        resetClientStorage(false, () => setClaimError('That reservation cannot be accessed anymore.'));
      }
    } catch (error: any) {
      // If we get a 404, the store will handle clearing the claim state
      if (error.statusCode !== 404) {
        resetClientStorage(false, () => setClaimError('An error occured whilst retrieving your reservation.'));
      }
    }
  }

  isLoading.value = false;
});
</script>
